import styled from "styled-components";

export const StyledLayout = styled.div`
  /* place navbar at top and footer at bottom of viewport */
  /* display: flex;
  flex-direction: column;
  justify-content: space-between; */
  /* height: 100vh; */

  /* place page content below navbar */
  .content {
    /* margin-top: 80px; */
  }
`;
